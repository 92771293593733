import { init } from '@sentry/browser';
init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.APP_ENV
});
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles/app.scss';
import './scripts/front/cookie.js';
import './scripts/front/show-hide-password.js';
import './scripts/front/crop.js';
import './scripts/front/modal-language';
import './scripts/front/modal-generic';
import {Dropdown} from 'bootstrap';

if(document.querySelectorAll('[data-bs-toggle="dropdown"]').length > 0) {
    new Dropdown('[data-bs-toggle="dropdown"]')
}
